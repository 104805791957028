.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


body {
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
  /* Cambiado para permitir el encabezado */
  align-items: center;
  margin: 3;

}

.navbar {
  width: 100%;

}

.container {
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 10px;
  margin: 10px;
  max-width: 500px;

}

h2 {
  color: #3897f0;
  /* Color azul de Instagram */
  text-align: center;
}

.form-control {
  border-radius: 5px;
}

.btn-primary {
  background-color: #3897f0;
  border-color: #3897f0;
}

.btn-primary:hover {
  background-color: #007bbd;
  border-color: #007bbd;
}

.card {
              margin: 10px;

  cursor: pointer;
  transition: transform 0.2s;
}

.card:hover {
  transform: scale(1.05);
}

.selected {
  border: 2px solid #3897f0;
  /* Color azul de Instagram */
  box-shadow: 0 4px 10px rgba(56, 151, 240, 0.5);
}

.hidden {
  display: none;
}

.course-image {
  width: 100%;
  height: auto;
  border-radius: 10px 10px 0 0;
  /* Bordes redondeados en la parte superior */
}
.logo-container { text-align: center; } .logo { max-width: 100%; height: auto; }